export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage?: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  instagram?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  // googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'Banda Musicale di Reana del Rojale',
  description: 'La Banda Musicale di Reana del Rojale è composta da circa 50 musicisti che sotto la guida del M° Flavio Luchitta svolgono attività sia concertistica che di accompagnamento di cerimonie civili e religiose, a livello regionale, nazionale e non solo. La scuola di musica, dove operano con professionalità docenti diplomati presso i conservatori, prepara i giovani ad affrontare l\'esperienza bandistica, ma anche, per chi lo desiderasse, per una futura carriera musicale attraverso il superamento degli esami di ammissione presso i conservatori.',
  coverImage: 'img/blog-cover.png',
  logo: 'img/ghost-logo.png',
  lang: 'it',
  siteUrl: 'https://www.bandareana.it',
  facebook: 'https://www.facebook.com/bandareana',
  instagram: 'https://www.instagram.com/bandamusicaledireanadelrojale',
  showSubscribe: false,
  mailchimpAction: 'https://twitter.us19.list-manage.com/subscribe/post?u=a89b6987ac248c81b0b7f3a0f&amp;id=7d777b7d75',
  mailchimpName: 'b_a89b6987ac248c81b0b7f3a0f_7d777b7d75',
  mailchimpEmailFieldName: 'MERGE0',
  // googleSiteVerification: 'GoogleCode',
  footer: 'based on Gatsby Casper',
};

export default config;
