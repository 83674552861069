import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import { css } from '@emotion/react';
import config from '../../website-config';

import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"

interface SiteNavLogoProps {
  strapiGlobal: {
    navLogo: {
      localFile: IGatsbyImageData;
    };
  };
}

export const SiteNavLogo = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        strapiGlobal {
          navLogo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
    `}
    render={(data: SiteNavLogoProps) => (
      <Link className="site-nav-logo" css={SiteNavLogoStyles} to="/">
        {data.strapiGlobal.navLogo ? (
          <GatsbyImage image={getImage(data.strapiGlobal.navLogo.localFile)!} alt={config.title}/>
          // <img src={data.logo.childImageSharp.fixed.src} alt={config.title} />
        ) : (
          config.title
        )}
      </Link>
    )}
  />
);

const SiteNavLogoStyles = css`
  position: relative;
  z-index: 100;
  flex-shrink: 0;
  display: inline-block;
  margin-right: 32px;
  padding: 12px 0;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-transform: none;

  :hover {
    text-decoration: none;
  }

  img {
    display: block;
    width: auto;
    height: 21px;
  }
`;

